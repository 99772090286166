<!-- 模板部分 -->
<template>
  <div class="table-container">
    <table class="data-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Bundle ID</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Address</th>
          <th>IDFV</th>
          <th>Timestamp</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in paginatedData" :key="item.id">
          <td>{{ item.id }}</td>
          <td>{{ item.bundle_id }}</td>
          <td>{{ item.latitude }}</td>
          <td>{{ item.longitude }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.idfv }}</td>
          <td>{{ item.timestamp }}</td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <button class="pagination-button" @click="prevPage" :disabled="currentPage <= 1">Prev</button>
      <span class="pagination-info">Page {{ currentPage }} of {{ pageCount }}</span>
      <button class="pagination-button" @click="nextPage" :disabled="hasNextPage == false">Next</button>
    </div>
  </div>
</template>

<!-- 脚本部分 -->
<script setup>
import { ref, computed ,onMounted } from 'vue';

const rawData = ref([]);

// 每页显示的条数
const perPage = 25;

// 当前页码
const currentPage = ref(1);
var hasNextPage = false;
// 计算属性，用于处理分页后的数据
const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * perPage;
  const end = start + perPage;
  return rawData.value.slice(start, end);
});

// 计算属性，用于计算总页数
const pageCount = computed(() => Math.ceil(rawData.value.length / perPage));

// 上一页
function prevPage() {
  if (currentPage.value > 1) {
    currentPage.value--;
    hasNextPage = true;
  }
}

// 下一页
function nextPage() {
  if (currentPage.value < pageCount.value) {
    currentPage.value++;
  }else
  {
    currentPage.value++;
    fetchData(currentPage.value,perPage);
  }
}

let formatter = new Intl.DateTimeFormat('zh-CN', {
    timeZone: 'Asia/Shanghai',
    year: 'numeric', month: '2-digit', day: '2-digit',
    hour: '2-digit', minute: '2-digit', second: '2-digit',
});


// 假设这是你的网络请求函数
async function fetchData(pages = 1,perPage = 50) {
  
  try {
    // 替换以下URL为你的API端点
    const response = await fetch("https://nd.apple-apns.com/api/list?uuid=81a5e540-389f-baf0-bb3b-bc8bda444e71&pages="+pages+"&pageCnt=" + perPage);
    // 确保响应状态码为2xx
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    // 解析JSON数据
    const data = await response.json();
    data.data.forEach(element => {
      var usdate = new Date (element.timestamp);
      element.timestamp =  formatter.format(usdate.getTime()+8*60*60*1000);
    });
    // 假设返回的数据直接是一个数组，你可以直接赋值给rawData
    rawData.value = [...rawData.value,...data.data];
    if(data.data.length >= perPage) {
      hasNextPage = true;
    }else{
      hasNextPage = false;
    }
  } catch (error) {
    console.error('There was a problem with your fetch operation:', error);
    // 在这里处理错误，比如显示一个错误消息
  }
}

function zzOnMounted(){
  fetchData(currentPage.value,perPage);
}



// 在组件挂载后调用fetchData函数
onMounted(zzOnMounted);
</script>

<style scoped>
.table-container {
  width: 100%;
  margin: 20px auto;
  overflow-x: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse; /* 确保边框不会重叠 */
  margin: 20px 0;
}


.data-table th,
.data-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd; /* 底部边框 */
  border-right: 1px solid #ddd; /* 右侧边框 */
}


.data-table th {
  background-color: #f2f2f2;
  color: #333;
}

/* 移除最后一列的右侧边框 */
.data-table tr td:last-child,
.data-table tr th:last-child {
  border-right: none;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  font-size: 16px;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-info {
  margin: 0 20px;
}
</style>
<!-- 样式部分 -->
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
